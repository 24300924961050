import React from "react";

import Layout from "../components/Layout";
import SwitchASBanner from "../components/SwitchASBanner";
import SwitchSteps from "../components/SwitchStep";
import SwitchContact from "../components/SwitchContact";

// Testing the image loading

const SwitchFromAuditShieldPage = () => {
  return (
    <Layout
      bg="home-bg"
      banner={<SwitchASBanner />}
      isDisplayBottomPartner={true}
    >
      <SwitchSteps />
      <SwitchContact formId="switch-contact-form" />
    </Layout>
  );
};

export default SwitchFromAuditShieldPage;
