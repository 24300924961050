import React from "react";

const SwitchSteps = () => {
  return (
    <div className="content switch-c">
      <div className="switch-title">
        Switch to AuditCover from Audit Shield in three simple steps.
      </div>
      <div className="switch-grid">
        <div className="switch-block-left">
          <div className="switch-as-badge">Audit Shield</div>

          <div className="switch-left-content">
            <div className="switch-upper switch-upper-title">
              Inside your Audit Shield account
            </div>
            <div className="switch-list-item">
              <div className="switch-number">1</div>
              <div className="switch-right">
                <div className="switch-right-txt">
                  Log into <b>Audit Shield portal</b> and go to <b>Reports</b>
                </div>
              </div>
            </div>

            <div className="switch-list-item">
              <div className="switch-number">2</div>
              <div className="switch-right">
                <div className="switch-right-txt">
                  Export <b>Paid</b>, <b>Unpaid</b>, and <b>Declined</b> reports
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="switch-block-right">
          <div className="switch-ac-badge">
            <img
              src="/images/new-landing-page/ac-badge.svg"
              alt="auditcover"
              loading="lazy"
            />
          </div>
          <div className="switch-right-content">
            <div className="switch-upper switch-upper-title txt-white">
              AuditCover will then
            </div>

            <div className="switch-list-item">
              <div className="switch-number txt-blue">1</div>
              <div className="switch-right">
                <div className="switch-right-txt txt-white">
                  Import all paid, unpaid, and declined clients
                </div>
              </div>
            </div>

            <div className="switch-list-item">
              <div className="switch-number txt-blue">2</div>
              <div className="switch-right">
                <div className="switch-right-txt txt-white">
                  Prepare <b>new quotes</b> for all&nbsp;
                  <b>active insured</b>&nbsp;and <b>uninsured clients</b>
                </div>
              </div>
            </div>

            <div className="switch-list-item">
              <div className="switch-number txt-blue">3</div>
              <div className="switch-right">
                <div className="switch-right-txt txt-white">
                  Set up your <b>campaign timing</b>
                </div>
              </div>
            </div>

            <div className="switch-list-item">
              <div className="switch-number txt-blue">4</div>
              <div className="switch-right">
                <div className="switch-right-txt txt-white">
                  Schedule a <b>quick review</b> session to finalise details
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchSteps;
