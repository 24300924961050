import React from "react";
import { Link } from "gatsby";

const SwitchASBanner = () => {
  return (
    <div className="banner">
      <div className="content">
        <div className="banner-wr">
          <div className="banner-txt text-white nu-c">
            <h1>
              Switch to AuditCover from Audit Shield and say goodbye to
              insurance admin
            </h1>
            <p className="banner-p text-white">
              Moving to AuditCover from Audit Shield is easy. Just share three
              simple reports and our team will handle the rest.
            </p>
            <div className="banner-btn-group">
              <a
                href="#switch-contact-form"
                className="cta long w-button cta-top-mirror btn-primary"
              >
                Book a demo
              </a>

              <Link
                to="#switch-contact-form"
                className="cta long w-button cta-top-mirror btn-secondary"
              >
                Get help switching
              </Link>
            </div>
          </div>
          <div className="banner-img">
            <div>
              <div
                className="switch-as-banner-img"
                style={{
                  borderRadius: "50px 50px 50px 0px",
                  overflow: "hidden",
                }}
              >
                <img
                  src="/images/new-landing-page/switch-from-as-banner.svg"
                  alt="Switch from AuditSheild"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchASBanner;
