import React, { useState } from "react";
import axios from "axios";

import Input from "./common/Input";

const SwitchContact = ({ formId }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    practiceName: "",
    email: "",
    mobile: "",
  });
  const [errors, setErrors] = useState({
    fullName: null,
    practiceName: null,
    email: null,
    mobile: null,
  });

  const [isDisplayForm, setIsDisplayForm] = useState(true);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Clear the error
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const validateInput = () => {
    let newErrors = {};

    // Validate the fullName field
    if (!formData.fullName) {
      newErrors.fullName = "Full name is required";
    }

    // Validate the practiceName field
    if (!formData.practiceName) {
      newErrors.practiceName = "Practice name is required";
    }

    // Validate the email field
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }

    // Validate the mobile field
    if (!formData.mobile) {
      newErrors.mobile = "Mobile is required";
    } else if (!/^(?:\+?61|0)4\d{8}$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile number is invalid";
    }

    // If there are errors, set the errors object in state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }

    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isInputValid = validateInput();
    if (!isInputValid) {
      return;
    }

    const baseAPIURL =
      "https://hb7lwijvspytknrzmbdskqlk7e0vldou.lambda-url.ap-southeast-2.on.aws/";

    const ApiRes = await axios.post(baseAPIURL, formData);

    // Send Slack notification
    const webhookUrl =
      "https://hooks.slack.com/services/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";
    const message =
      `--------------------------------------\n` +
      `*New Partner Request*\n` +
      `*Full Name:* ${formData.fullName}\n` +
      `*Practice Name:* ${formData.practiceName}\n` +
      `*Email:* ${formData.email}\n` +
      `*Mobile:* ${formData.mobile}\n` +
      `--------------------------------------`;

    const data = { text: message };

    let slackRes = await axios.post(webhookUrl, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (data) => {
          return data;
        },
      ],
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "hRf4XytJ1n7qMX8P6xL79ajHTqNM8eWL9TwwPBNLHide",
      },
    });

    if (ApiRes.status === 200 || slackRes.status === 200) {
      setIsDisplayForm(false);
    }
  };

  return (
    <div className="switch-contact-c" id={formId}>
      <div className="content">
        <div className="switch-contact-grid">
          <div className="switch-contact-left">
            <div className="switch-contact-left-title">
              Switching to AuditCover has never been simpler
            </div>

            <div className="switch-contact-desc">
              <div className="switch-contact-left-icon">
                <img
                  src="/images/new-landing-page/tick-icon.svg"
                  alt="tick"
                  loading="lazy"
                />
              </div>
              Dedicated tax audit insurance specialist to help you switch
            </div>

            <div className="switch-contact-desc">
              <div className="switch-contact-left-icon">
                <img
                  src="/images/new-landing-page/tick-icon.svg"
                  alt="tick"
                  loading="lazy"
                />
              </div>
              Telephone and email support
            </div>

            <div className="switch-contact-desc">
              <div className="switch-contact-left-icon">
                <img
                  src="/images/new-landing-page/tick-icon.svg"
                  alt="tick"
                  loading="lazy"
                />
              </div>
              Up and running in a few days with no further work needed
            </div>
          </div>

          <div className="switch-contact-right">
            {isDisplayForm ? (
              <form onSubmit={handleFormSubmit}>
                <Input
                  type="text"
                  name="fullName"
                  className="switch-contact-input"
                  placeholder="Full name"
                  value={formData.fullName}
                  error={errors.fullName}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  name="practiceName"
                  className="switch-contact-input"
                  placeholder="Practice name"
                  value={formData.practiceName}
                  error={errors.practiceName}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  name="email"
                  className="switch-contact-input"
                  placeholder="Email"
                  value={formData.email}
                  error={errors.email}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  name="mobile"
                  className="switch-contact-input"
                  placeholder="Mobile"
                  value={formData.mobile}
                  error={errors.mobile}
                  onChange={handleChange}
                />
                <button className="switch-submit-btn">Submit</button>
              </form>
            ) : (
              <div className="form-success-msg">
                Thanks for the request! We will get in touch with you shortly.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchContact;
